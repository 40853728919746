import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/styles/index.scss";
import VueYandexMetrika from "vue-yandex-metrika";
import { i18n } from "@/i18n";
import AsyncComputed from "vue-async-computed";
import ClickOutside from "vue-click-outside";
import Button from "./components/ewa/general/Button.vue";
import Icon from "./components/ewa/general/Icon.vue";
import VueTheMask from "vue-the-mask";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.directive(ClickOutside);

Vue.use(AsyncComputed);

Vue.component("EButton", Button);
Vue.component("EIcon", Icon);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VueTheMask);

Vue.use(VueYandexMetrika, {
  id: 80365585,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  i18n,
  router,
  store,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
