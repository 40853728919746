import Vue from "vue";
import VueI18n from "vue-i18n";
import { Locales } from "./locales";

import en from "./en.json";
import ru from "./ru.json";

export const messages = {
  [Locales.EN]: en,
  [Locales.RU]: ru,
};

export const defaultLocale = Locales.RU;

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});
